<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col cols="12" lg="7" md="7">
        <v-card flat tile>
          <div class="text-left subtitle">
            Painel da página inicial
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col cols="12" sm="12" md="12">
        <v-card>
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-row>
                <v-btn
                  v-if="content[0]"
                  color="primary"
                  class="mx-3 float-right"
                  text
                  dense
                  @click.prevent="dialogSlideAdd()"
                >
                  <v-icon left color="#F7A400">mdi-tooltip-edit</v-icon
                  >Actualizar Conteudo
                </v-btn>
                <v-btn
                  color="primary"
                  class="mx-3 float-right"
                  text
                  dense
                  @click.prevent="dialogSlideAdd()"
                  v-else
                >
                  <v-icon left color="#F7A400">mdi-plus-circle-outline</v-icon
                  >Adicionar Conteudo
                </v-btn>

                <v-spacer></v-spacer>
              </v-row>
              <v-container>
                <v-card>
                  <v-container>
                    <div v-if="content[0]">
                      <p class="font-weight-black">
                        {{ content[0].title }}
                      </p>
                      <p class="text--secondary">
                        {{ content[0].description }}
                      </p>
                      <p class="text--disabled">
                        {{ content[0].tip }}
                      </p>
                    </div>
                  </v-container>
                </v-card>
              </v-container>
            </v-col>

            <v-col cols="6" sm="6" md="6">
              <v-row>
                <croppa
                  v-model="croppa"
                  style="display: none"
                  ref="croppa"
                  @new-image-drawn="onFilePicked"
                >
                </croppa>
                <v-btn
                  color="primary"
                  class="mx-3 float-right"
                  text
                  dense
                  @click="$refs.croppa.chooseFile()"
                >
                  <v-icon left color="#F7A400">mdi-plus-circle-outline</v-icon
                  >Adicionar Slide
                </v-btn>
                <v-spacer></v-spacer>
                <!-- <input
                  type="file"
                  style="display: none"
                  ref="croppa"
                  accept="image/*"
                    @change="onFilePicked"
                /> -->
              </v-row>
              <v-container>
                <!-- {{ imagesUrl }} -->
                <v-carousel v-model="model">
                  <v-carousel-item
                    v-for="(image, i) in imagesUrl"
                    :key="i"
                    :src="image.image"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                    @click="getPosition(image)"
                  >
                  </v-carousel-item>
                </v-carousel>
              </v-container>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <formHome></formHome>
    <progressLoad v-if="progressbar"></progressLoad>
    <editDeletSlide
      v-if="form"
      :slide="slide"
      @back="closeDialog"
    ></editDeletSlide>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import formHome from "./../components/formHome.vue";
import progressLoad from "./../components/progress.vue";
import editDeletSlide from "./../components/editDeletSlide.vue";

import { CONTENT_HOME_QUERY, SLIDE_HOME_QUERY } from "./../graphql/Query";
import { SEND_UPLOAD_SLIDE } from "./../graphql/Mutation";
import {
  UPDATED_CONTENT_HOME,
  UPDATED_CONTENT_SLIDE,
} from "./../graphql/subscription";
import { API_URL } from "./../../../../../../api";
export default {
  components: {
    formHome,
    editDeletSlide,
    progressLoad,
  },
  data: () => ({
    search: "",
    model: 0,
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
    content: [],
    file: false,
    imagesUrl: [],
    uri: API_URL + "/images/slide/",
    form: false,
    progressbar: false,
    slide: [],
    croppa: {},
  }),
  apollo: {
    getContentHome: {
      query: CONTENT_HOME_QUERY,
      // fetchPolicy: "no-cache",

      result({ data }) {
        if (data.getContentHome) {
          this.content = data.getContentHome;
          var about = [];
          this.content[0].about.forEach((element) => {
            var title = element.title;
            var description = element.description;
            about.push({ title, description });
          });
          this.content[0].about = about;
        }
      },
    },
    getSlideHome: {
      query: SLIDE_HOME_QUERY,
      // fetchPolicy: "no-cache",
      result({ data }) {
        if (data.getSlideHome) {
          var array = [];
          var value = [];
          data.getSlideHome.forEach((element) => {
            value = {
              id: element._id,
              image: this.uri + "" + element.name,
            };
            array.push(value);
          });
          this.imagesUrl = array;
        }
      },
    },
    $subscribe: {
      message: {
        query: UPDATED_CONTENT_HOME,
        result({ data }) {
          if (data.addHomeContent) {
            this.content = [data.addHomeContent];
            console.log("content", this.content);
          }
        },
      },
      slides: {
        query: UPDATED_CONTENT_SLIDE,
        result({ data }) {
          if (data.addSlide) {
            console.log("content", data.addSlide);
            var array = [];
            var value = [];
            data.addSlide.content.forEach((element) => {
              value = {
                id: element._id,
                image: this.uri + "" + element.name,
              };
              array.push(value);
            });
            this.imagesUrl = array;
          }
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  async created() {
    // this.$emit(`update:layout`, Home);
  },
  methods: {
    dialogSlideAdd() {
      // eslint-disable-next-line no-undef
      Fire.$emit("dialogSlideAdd");
    },
    pickFile() {
      this.$refs.croppa.chooseFile();
    },
    async onFilePicked() {
      if (this.croppa.hasImage()) {
        const image = this.croppa.generateDataUrl("image/jpeg", 0.8);
        this.progressbar = true;
        await this.$apollo
          .mutate({
            mutation: SEND_UPLOAD_SLIDE,
            variables: {
              image,
            },
            context: {
              hasUpload: true,
            },
          })
          .then((data) => {
            console.log(data);
            this.progressbar = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getPosition(slide) {
      this.form = true;
      this.slide = slide;
    },
    closeDialog() {
      this.form = false;
    },
  },
};
</script>
