import gql from 'graphql-tag'
export const UPDATED_CONTENT_HOME = gql `
subscription addHomeContent{
  addHomeContent{
    title
    description
    tip
    date
    about{
      title
      description
    }
  }
}
`
export const UPDATED_CONTENT_SLIDE = gql `
subscription addSlide{
  addSlide{
     content{
      _id
      name
    }
  }
}
`