<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card>
        <v-card-title class="justify-end primary--text caption pa-1">
          <v-btn x-small icon @click="back">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="primary--text text-center justify-center pt-1">
          <span class="pt-1">Como pretende proceder?</span>
          <v-row justify="center">
            <v-col>
              <croppa
                v-model="croppa"
                style="display: none"
                ref="croppa"
                @new-image-drawn="onFilePicked"
              >
              </croppa>
              <v-btn block color="primary" @click="$refs.croppa.chooseFile()"
                >Alterar</v-btn
              >
            </v-col>
            <v-col>
              <v-btn block color="primary" outlined @click="deleteSlide"
                >Eliminar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <progressLoad v-if="progressbar"></progressLoad>
    <v-snackbar v-model="snackbar">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { DELETE_SLIDE, UPDATE_SLIDE } from "./../graphql/Mutation";
import progressLoad from "./progress";
import { mapGetters } from "vuex";
export default {
  props: ["slide"],
  components: {
    progressLoad,
  },
  data: () => ({
    dialog: true,
    valid: true,
    snackbar: false,
    progressbar: false,
    text: `Actualizado com sucesso!`,
  }),

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  methods: {
    async deleteSlide() {
      console.log(this.slide.id);
      this.progressbar = true;
      await this.$apollo
        .mutate({
          mutation: DELETE_SLIDE,
          variables: {
            id: this.slide.id,
          },
        })
        .then((data) => {
          this.text = "Slide eliminado com sucesso!";
          this.snackbar = true;
          this.progressbar = false;
          this.dialog = !this.dialog;
          this.$emit("back");
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    back() {
      this.dialog = false;
      this.$emit("back");
    },
    pickFile() {
      this.$refs.image.click();
    },
    async onFilePicked() {
      if (this.croppa.hasImage()) {
        const image = this.croppa.generateDataUrl("image/jpeg", 0.8);
        this.progressbar = true;
        await this.$apollo
          .mutate({
            mutation: UPDATE_SLIDE,
            variables: {
              id: this.slide.id,
              image: image,
            },
            context: {
              hasUpload: true,
            },
          })
          .then((data) => {
            this.text = "Slide alterado com sucesso!";
            this.snackbar = true;
            this.progressbar = false;
            this.dialog = !this.dialog;
            this.$emit("back");
            console.log(data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  created() {
    this.dialog = true;
  },
};
</script>
