import gql from 'graphql-tag'

export const SEND_CONTENT_HOME = gql `
  mutation addHomeContent($title: String, $description: String, $tip: String, $date: String, $about: [AboutInput]) {
    addHomeContent(title:$title, description:$description, tip:$tip, date:$date, about:$about){
      title
      description
      tip 
      about{
        title
        description
      }
    }
  }
`
export const SEND_UPLOAD_SLIDE = gql `

  mutation addPhoto($image: Upload) {
    addPhoto(image: $image){
      name
    }
  }
`

export const DELETE_SLIDE = gql `

  mutation deletePhoto($id: ID) {
    deletePhoto(id: $id)
  }
`

export const UPDATE_SLIDE = gql `

  mutation editPhoto($id: ID,$image: Upload) {
    editPhoto(id: $id,image: $image)
  }
`