<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="750">
      <v-card elevation="2" class="pb-5">
        <v-card-title class="justify-center">{{ textCard.title }}</v-card-title>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
                v-model="title"
                :counter="1000"
                :rules="titleRules"
                label="Titulo"
                required
                outlined
                prepend-inner-icon="mdi-format-clear"
              ></v-text-field>
            <v-textarea
              v-model="description"
              :counter="1000"
              :rules="descriptionRules"
              clearable
              clear-icon="mdi-close-circle"
              label="Descrição"
              value="This is clearable text."
            ></v-textarea>
            <v-text-field
              v-model="tip"
              :counter="1000"
              :rules="tipRules"
              label="Pequena descrição"
              required
            ></v-text-field>

            <!-- <template v-for="(ab, index) in about">
              <v-col
                cols="12"
                class="py-0 my-0"
                :key="index"
              >
                <v-row class="py-0 my-0">
                  <v-col
                    cols="12"
                    md="6"
                    class="py-0"
                  >
                    <v-text-field
                      v-model="ab.title"
                      label="Titulo FAQ"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                    class="py-0 my-0"
                  >
                    <v-text-field
                      v-model="ab.description"
                      label="Descrição FAQ"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                    class="py-0 my-0 text-center"
                  >
                    <v-btn
                      v-if="index === 0"
                      x-small
                      outlined
                      fab
                      color="secondary"
                      @click="addAbout"
                    >
                      <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                      v-else
                      small
                      icon
                      fab
                      color="secondary"
                      @click="removeAbout(index)"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </template> -->
            <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
            <v-btn color="primary" text class="mr-4" @click="dialog = !dialog">
              Cancelar
            </v-btn>

            <v-btn color="primary" class="mr-4" @click="validate">
              Salvar
            </v-btn>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
    <progressLoad v-if="progressbar"></progressLoad>
    <v-snackbar v-model="snackbar">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import moment from "moment";
import { CONTENT_HOME_QUERY } from "./../graphql/Query";
import { SEND_CONTENT_HOME } from "./../graphql/Mutation";
import progressLoad from "./progress";
import { mapGetters } from "vuex";
export default {
  components: {
    progressLoad,
  },
  data: () => ({
    dialog: false,
    valid: true,
    title: "",
    titleRules: [
      (v) => !!v || "Titulo é obrigatoria",
      (v) =>
        (v && v.length <= 1000) ||
        "O titulo não pode possuir mais de 1000 caracteres",
    ],
    description: "",
    descriptionRules: [
      (v) => !!v || "Descrição é obrigatoria",
      (v) =>
        (v && v.length <= 1000) ||
        "A descrição não pode possuir mais de 1000 caracteres",
    ],
    tip: null,
    tipRules: [
      (v) => !!v || "Pequena descrição é obrigatoria",
      (v) =>
        (v && v.length <= 1000) ||
        "A pequena descrição não pode possuir mais de 1000 caracteres",
    ],
    about: [
      {
        title: "",
        description: "",
      },
    ],
    date: moment().format("LL"),
    snackbar: false,
    progressbar: false,
    text: `Conteudo salvos com sucesso`,
    id: 0,
  }),
  apollo: {
    getContentHome: {
      query: CONTENT_HOME_QUERY,
      // fetchPolicy: "no-cache",
      result({ data }) {
        if (data.getContentHome[0]) {
          this.title = data.getContentHome[0].title;
          this.description = data.getContentHome[0].description;
          this.tip = data.getContentHome[0].tip;
          this.about = data.getContentHome[0].about;
          this.id = data.getContentHome[0]._id;
          var about = [];
          this.about.forEach((element) => {
            var title = element.title;
            var description = element.description;
            about.push({ title, description });
          });
          this.about = about;

        }
      },
    },
    $subscribe: {},
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    textCard() {
      return this.id
        ? { title: "FORMULÁRIO PARA EDITAR O CONREÚDO DO HOME" }
        : { title: "FORMULÁRIO PARA CADASTRAR O CONREÚDO DO HOME" };
    },
  },
  methods: {
    async validate() {
      this.$refs.form.validate();
      if (
        this.title === "" ||
        this.description === "" ||
        this.tip === "" ||
        this.about === null
      ) {
        return;
      }
      this.progressbar = true;
      await this.$apollo
        .mutate({
          mutation: SEND_CONTENT_HOME,
          variables: {
            title: this.title,
            description: this.description,
            tip: this.tip,
            about: this.about,
            date: moment().format("LL"),
          },
        })
        .then((data) => {
          this.snackbar = true;
          this.dialog = !this.dialog;
          console.log(data);
          this.progressbar = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addAbout() {
      this.about.push({ title: "", description: "" });
    },
    removeAbout(index) {
      this.about.splice(index, 1);
    },
  },
  created() {
    // eslint-disable-next-line no-undef
    Fire.$on("dialogSlideAdd", () => {
      this.dialog = true;
    });
  },
};
</script>
